<template>
  <div class="main-view">
    <div class="workWrap">
      <el-row class="s-container">
        <el-col :lg="5" :md="12" :sm="12" :xs="24" class="s-card w-20">
          <div class="s-card-body" >
            <div class="s-card-con">
              <div class="s-card-name">注册用户</div>
              <div class="s-card-num"><b>{{dataInfo.user.total}}</b>人</div>
              <div class="s-card-classify">
                <p><span>需求用户</span><span class="num-color">{{dataInfo.user.hire}}人</span></p>
                <p><span>工人师傅</span><span class="num-color">{{dataInfo.user.worker}}人</span></p>
              </div>
              <div class="s-card-line"></div>
            </div>
          </div>
        </el-col>
        <el-col :lg="5" :md="12" :sm="12" :xs="24" class="s-card w-20">
          <div class="s-card-body" >
            <div class="s-card-con">
              <div class="s-card-name">需求总量</div>
              <div class="s-card-num"><b>{{dataInfo.job.total}}</b>单</div>
              <div class="s-card-classify">
                <p><span>招工中</span><span class="num-color">{{dataInfo.job.march}}单</span></p>
                <p><span>已结束</span><span class="num-color">{{dataInfo.job.finish}}单</span></p>
              </div>
              <div class="s-card-line"></div>
            </div>
          </div>
        </el-col>
        <el-col :lg="5" :md="12" :sm="12" :xs="24" class="s-card w-20">
          <div class="s-card-body" >
            <div class="s-card-con">
              <div class="s-card-name">工单总数</div>
              <div class="s-card-num"><b>{{dataInfo.order.total}}</b>单</div>
              <div class="s-card-classify">
                <p><span>有效工单</span><span class="num-color">{{dataInfo.order.march}}单</span></p>
                <p><span>无效工单</span><span class="num-color">{{dataInfo.order.finish}}单</span></p>
              </div>
              <div class="s-card-line"></div>
            </div>
          </div>
        </el-col>
        <el-col :lg="9" :md="12" :sm="12" :xs="24" class="s-card w-20">
          <div class="s-card-body" >
            <div class="s-card-con">
              <div class="s-card-name">交易金额</div>
              <div class="s-card-num"><b>{{dataInfo.amount.total}}</b>元</div>
              <div class="s-card-classify">
                <p><span>待到账</span><span class="num-color">{{dataInfo.amount.prepay}}元</span></p>
                <p><span>已到账</span><span class="num-color">{{dataInfo.amount.await}}元</span></p>
                <p><span>已退款</span><span class="num-color">{{dataInfo.amount.refund}}元</span></p>
              </div>
              <div class="s-card-line"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      
      <div style="margin:20px 60px;font-size:18px;font-weight: bold;">区域统计</div>
      <el-row>
        <el-col :span="8">
          <div class="chart1">
            <Echart :options="options1" height="100%" width="100%"></Echart>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="chart1">
            <Echart :options="options2" height="100%" width="100%"></Echart>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="chart1">
            <Echart :options="options3" height="100%" width="100%"></Echart>
          </div>
        </el-col>
        
      </el-row>

      <div  style="margin:20px 60px;display:flex;align-items: center; justify-content: space-between; ">
        <div class="selTime" style="display:flex;">
          <div style="font-size:18px;font-weight: bold;">平均日活率</div>
          <!-- <div class="ul">
            <div class="li" :class="dateStatus === 0 ? 'current' : ''" @click="handleDate(0)">最近7天</div>
            <div class="li" :class="dateStatus === 1 ? 'current' : ''" @click="handleDate(1)">最近15天</div>
            <div class="li" :class="dateStatus === 2 ? 'current' : ''" @click="handleDate(2)">最近30天</div>
            <div class="li" :class="dateStatus === 3 ? 'current' : ''" @click="handleDate(3)">最近90天</div>
          </div> -->
        </div>
        <el-date-picker
          v-model="selDate"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="handleDateChange"
          :clearable="false">
        </el-date-picker>
      </div>
      <!-- Echarts表格 -->
      <div class="myChart"></div>
    </div>
  </div>
</template>

<script>
import Echart from "@/components/echart/index.vue";
import * as $echarts from 'echarts'
export default {
  name: 'Home',
  components:{
    Echart,
  },
  data() {
    return {
      selDate: [],
      dataInfo: {
        amount: {},
        job: {},
        order: {},
        user: {},
        line: [],
        line_time: [],
        rate: [],
        reg: {}, // 注册分析
        need: {}, // 需求分析
        trade: {}, // 交易分析
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近7天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近15天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近90天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateStatus: 0,
      options1: {
        title: {
          // text: '注册分析',
          text: '注册分析\n' + '（人）',
          x: '50%',
          y: '47%',
          textAlign:'center',
        }, 
        grid:{
          left:'10%'
        },
        legend: { show: false },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: { borderColor: '#fff', borderWidth: 2 },
            color: [
                '#52A8FF',
                '#00B389',
                '#E27272',
                '#FFC53D',
                '#006EFE',
                '#F5855F',
                '#C099FC',
                '#FFA940',
                '#29EFC4',
                '#F8AEA4',
            ],
            label: {
              // alignTo: 'labelLine', // ! 文字对齐方式
              formatter: function (e) {
                let {
                    data: { value, name, percent },
                } = e;
                return `{x|}{a|${name}}\n{b|${percent}%,}{c|${value}人}`;
              },
              minMargin: 5,
              lineHeight: 15,
              rich: {
                x: { width: 10, height: 10, backgroundColor: 'inherit', borderRadius: 5 },
                a: { fontSize: 14, color: 'inherit', padding: [0, 20, 0, 8] },
                b: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 18] },
                c: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 8] },
              },
            },
            data: [
              { value: 28, name: '家具安装', percent: '27.46%' },
              { value: 18, name: '餐饮帮厨', percent: '30.51%' },
              { value: 11, name: '搬家拉货', percent: '11.69%' },
              { value: 31, name: '打墙钻孔', percent: '25.08%' },
              { value: 11, name: '泥工瓦匠', percent: '21.69%' },
              { value: 21, name: '家电维修', percent: '23.39%' },
              { value: 16, name: '其他服务', percent: '18.17%' },
            ],
          },
        ],
      },
      options2: {
        title: {
          // text: '需求分析',
          text: '需求分析\n' + '（个）',
          x: '50%',
          y: '47%',
          textAlign:'center',
        }, 
        grid:{
          left:'10%'
        },
        legend: { show: false },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: { borderColor: '#fff', borderWidth: 2 },
            color: [
                '#52A8FF',
                '#00B389',
                '#E27272',
                '#FFC53D',
                '#006EFE',
                '#F5855F',
                '#C099FC',
                '#FFA940',
                '#29EFC4',
                '#F8AEA4',
            ],
            label: {
              // alignTo: 'labelLine', // ! 文字对齐方式
              formatter: function (e) {
                let {
                    data: { value, name, percent },
                } = e;
                return `{x|}{a|${name}}\n{b|${percent}%,}{c|${value}个}`;
              },
              minMargin: 5,
              lineHeight: 15,
              rich: {
                x: { width: 10, height: 10, backgroundColor: 'inherit', borderRadius: 5 },
                a: { fontSize: 14, color: 'inherit', padding: [0, 20, 0, 8] },
                b: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 18] },
                c: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 8] },
              },
            },
            data: [
              { value: 28, name: '家具安装', percent: '27.46%' },
              { value: 18, name: '餐饮帮厨', percent: '30.51%' },
              { value: 11, name: '搬家拉货', percent: '11.69%' },
              { value: 31, name: '打墙钻孔', percent: '25.08%' },
              { value: 11, name: '泥工瓦匠', percent: '21.69%' },
              { value: 21, name: '家电维修', percent: '23.39%' },
              { value: 16, name: '其他服务', percent: '18.17%' },
            ],
          },
        ],
      },
      options3: {
        title: {
          // text: '交易分析',
          text: '交易分析\n' + '（元）',
          x: '50%',
          y: '47%',
          textAlign:'center',
        },
        grid:{
          left:'10%'
        },
        legend: { show: false },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: { borderColor: '#fff', borderWidth: 2 },
            color: [
                '#52A8FF',
                '#00B389',
                '#E27272',
                '#FFC53D',
                '#006EFE',
                '#F5855F',
                '#C099FC',
                '#FFA940',
                '#29EFC4',
                '#F8AEA4',
            ],
            label: {
              // alignTo: 'labelLine', // ! 文字对齐方式
              formatter: function (e) {
                let {
                    data: { value, name, percent },
                } = e;
                return `{x|}{a|${name}}\n{b|${percent}%,}{c|${value}元}`;
              },
              minMargin: 5,
              lineHeight: 15,
              rich: {
                x: { width: 10, height: 10, backgroundColor: 'inherit', borderRadius: 5 },
                a: { fontSize: 14, color: 'inherit', padding: [0, 20, 0, 8] },
                b: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 18] },
                c: { fontSize: 12, align: 'left', color: '#666666', padding: [8, 0, 0, 8] },
              },
            },
            data: [
              { value: 28, name: '家具安装', percent: '27.46%' },
              { value: 18, name: '餐饮帮厨', percent: '30.51%' },
              { value: 11, name: '搬家拉货', percent: '11.69%' },
              { value: 31, name: '打墙钻孔', percent: '25.08%' },
              { value: 11, name: '泥工瓦匠', percent: '21.69%' },
              { value: 21, name: '家电维修', percent: '23.39%' },
              { value: 16, name: '其他服务', percent: '18.17%' },
            ],
          },
        ],
      },
    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.selDate[0] = this.$moment(start).format("YYYY-MM-DD");
    this.selDate[1] = this.$moment(end).format("YYYY-MM-DD");
  },
  mounted() {
    this.getBaseInfo();
  },
  methods: {
    // 获取数据
    async getBaseInfo() {
      let _params = {
        start_time: this.selDate.length > 0 ? this.selDate[0] : '',
        end_time: this.selDate.length > 0 ? this.selDate[1] : '',
      }
      await this.$http.get('admin/flex_index/index',{params:_params}).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data;
          this.options1.series[0].data = res.data.reg.map(a => {
            return {
              value: a.num,
              name: a.name,
              percent: a.rate
            }
          })
          this.options2.series[0].data = res.data.need.map(b => {
            return {
              value: b.num,
              name: b.name,
              percent: b.rate
            }
          })
          this.options3.series[0].data = res.data.trade.map(c => {
            return {
              value: c.num,
              name: c.name,
              percent: c.rate
            }
          })
        } else {
          this.$message.error(res.msg);
        }
      })
      await this.init()
    },
    handleDateChange() {
      this.getBaseInfo()
    },
    // 选择日期查询
    handleDate(i) {
      let objDate = {
        0: 7 * 60 * 60 * 24 * 1000,
        1: 15 * 60 * 60 * 24 * 1000,
        2: 30 * 60 * 60 * 24 * 1000,
        3: 90 * 60 * 60 * 24 * 1000,
      }
      this.dateStatus = i
      let date = new Date().getTime();
      let start_time = this.setDates(date - objDate[i]);
      let end_time = this.setDates();
      this.selDate = [start_time, end_time]
      console.log(this.selDate)
      this.getBaseInfo()
    },
    setDates(d) {
      let date = d ? new Date(d) : new Date();
      let y = date.getFullYear();
      let m =
        date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
      let d1 = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
      return y + "-" + m + "-" + d1;
    },
    // 绘制图表
    init() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',  // 鼠标移上去显示（cross）
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['活跃用户', '日活率']
        },
        xAxis: [
          {
            type: 'category',
            // data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07','06-08','06-09'],
            data: this.dataInfo.line_time,
            axisPointer: {
              type: 'line',  // 鼠标移上去显示（虚线：line，阴影：shadow）
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '人数（人）',
            min: 0,
            // max: 800,
            interval: 200,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '日活率(%)',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '活跃用户',
            type: 'bar',
            barWidth: '20%',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              }
            },
            // data: [29, 59, 91, 126, 28, 87, 16, 82, 57],
            data: this.dataInfo.line,
            itemStyle: {
              // 普通样式。
              normal: {
                // 柱子的颜色。
                color: 'rgba(255, 212, 143, 0.4)'
              }
            }
          },
          {
            name: '日活率',
            type: 'line',
            smooth:true,
            symbolSize: 15,
            lineStyle: {
              width: 5,
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            // data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 2.3, 6.5, 3.8],
            data: this.dataInfo.rate,
            itemStyle: {
              // 普通样式。
              normal: {
                // 点的颜色。
                color: '#007DF2'
              }
            }
          }
        ]
      };

      const myChart = $echarts.init(document.querySelector(".myChart"));
      // 基于准备好的dom,绘制图表
      myChart.setOption(option);

      window.onresize = function() {
        myChart.resize()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.workWrap {
  background: #fff;
  height: 100%;
  overflow-y: scroll;
}
.s-container {
  border-bottom: solid 1px #eee;
}
.s-card {
  cursor: pointer;
  padding: 18px 0;
  overflow: hidden;
  position: relative;
}
.s-card-body {
  display: flex;
}
.s-card-img {
  float: left;
  width: 61px;
  height: 61px;
  margin-right: 11px;
}
.s-card-con {
  width: 100%;
  float: left;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  color: rgba(130, 130, 130, 1);
}
.s-card-name {
  margin-bottom: 10px;
}
.s-card-num b {
  font-size: 32px;
  color: #303133;
}
.s-card-line {
  position: absolute;
  width: 2px;
  height: 80px;
  background: #f4f4f4;
  right: 0;
  top: 30px;
}
.s-card-classify {
  display: flex;
  justify-content: space-evenly;
}
.num-color {
  color: #303133;
  font-weight: bold;
  margin-left: 5px;
}
.myChart {
  margin-top: 50px;
  width: 100%;
  height: 500px;
}
.selTime .item{
  margin-left: 20px;
  cursor: pointer;
}
.active {
  border-bottom: solid 2px #007df2;
  color: #007df2;
  padding-bottom: 5px;
}
.ul {
  display: flex;
  font-size: 16px;
  color: #262626;

  .li {
    margin-left: 15px;
    cursor: pointer;
  }
}
.current {
  color: #278aff;
  border-bottom: 2px solid #278aff;
  padding-bottom: 5px;
}
.chart1 {
  width: 100%;
  height: 350px;
}
</style>